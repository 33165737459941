import { RepositoryFactory } from "@/repositories/RepositoryFactory";
import moment from "moment";
moment.locale("it");

export default {
  methods: {
    uploadFile(file, upload) {
      if (file.item.import_file_code !== file.item.value.name) {
        this.$showSnackbar({
          preset: "success",
          text: "Il nome del file inserito non è valido",
        });
        return;
      }
      // Prepare upload form
      let formData = new FormData();
      formData.append("file", file.item.value);
      formData.append("import_file_id", file.item.import_file_id);
      formData.append(
        "source",
        `${upload.source}_${this.$store.state.auth.settings.agency_id}`
      );
      // Store File
      file.item.isLoading = true;
      const Repo = RepositoryFactory.get(this.temp_import);
      Repo.store(formData)
        .then((response) => {
          file.item.file_id = response.data.data.id;
          file.item.isStored = true;
          file.item.isLoading = false;
          this.$showSnackbar({
            preset: "success",
            text: `File Caricato`,
          });
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
          file.item.isLoading = false;
        });
    },
    deleteFile(data) {
      // Delete File
      data.item.isLoading = true;
      const Repo = RepositoryFactory.get(this.temp_import);
      Repo.destroy(data.item.file_id)
        .then(() => {
          data.item.isStored = false;
          data.item.isLoading = false;
          this.$showSnackbar({
            preset: "success",
            text: `File Eliminato`,
          });
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
          data.item.isLoading = false;
        });
    },
    startTransmission(section, upload) {
      // Api call
      section.isLoading = true;
      const Repo = RepositoryFactory.get(this.temp_import);
      Repo.spool(
        `${upload.source}_${this.$store.state.auth.settings.agency_id}`
      )
        .then(() => {
          // console.log(section);
          // console.log(upload), this.initDefault(upload);
          section.isLoading = false;
          this.$showSnackbar({
            preset: "success",
            text: `Trasmissione dati avvenuta con successo`,
          });
        })
        .catch((error) => {
          this.$showSnackbar({
            preset: "error",
            text: `${error}`,
          });
          section.isLoading = false;
        });
    },
    initDefault(upload) {
      let ut = moment().unix();
      upload.source = moment(ut).format("x");
      upload.files.forEach((file) => {
        file.isStored = false;
        file.value = null;
      });
    },
    groupByCategory(data) {
      const config = [];

      // // Categoria (import_categories)
      const category = {
        type: null,
        category: null,
        title: null,
        isLoading: false,
        source: null,
        upload: [],
      };

      // Group (import_groups)
      const group = {
        type: null,
        title: null,
        source: null,
        files: [],
      };

      // File (import_files)
      const file = {
        import_file_code: null,
        import_file_id: null,
        file_id: null,
        value: null,
        isStored: false,
        isLoading: false,
      };

      let ut = moment().unix();
      data.forEach((import_file) => {
        // Levels
        const categoryLevel = import_file.import_item.import_group;
        const groupLevel = import_file.import_item;
        const fileLevel = import_file;

        // Add Category (Level 1)
        const checkCategory = config.find(
          (category) => category.type === categoryLevel.code
        );
        if (!checkCategory) {
          let categoryCopy = JSON.parse(JSON.stringify(category));
          categoryCopy.type = categoryLevel.code;
          categoryCopy.category = categoryLevel.title;
          config.push(categoryCopy);
        }

        // Add Group (Level 2)
        let checkGroup;
        config.forEach((category) => {
          checkGroup = category.upload.find(
            (upload) => upload.type === groupLevel.id
          );
        });

        if (!checkGroup) {
          let groupCopy = JSON.parse(JSON.stringify(group));
          groupCopy.type = groupLevel.id;
          groupCopy.title = groupLevel.title;
          const cat = config.find(
            (category) => category.type === categoryLevel.code
          );
          cat.upload.push(groupCopy);
        }

        // Add File (Level 3)
        let fileCopy = JSON.parse(JSON.stringify(file));
        fileCopy.import_file_code = fileLevel.title;
        fileCopy.import_file_id = fileLevel.id;

        let grp;
        config.forEach((category) => {
          grp = category.upload.find((upload) => upload.type === groupLevel.id);
        });
        // Check if the file has been already uploaded
        const found = import_file.temp_imports.find(
          (uploaded_file) => uploaded_file.is_parsed.value === "N"
        );
        if (found) {
          fileCopy.file_id = found.id;
          // Set group source
          grp.source = found.source;
          // Disable Upload Button
          fileCopy.isStored = true;
        } else {
          //Generate new source
          if (!grp.source) {
            grp.source = moment(ut).format("x");
            ut += 1;
          }
        }
        grp.files.push(fileCopy);
      });
      return config;
    },
    fetch() {
      this.isLoading = true;
      // Retrieve Import Files by code
      const Repo = RepositoryFactory.get(this.import_file);
      let queryString = `byTempImport&byImportGroup&byImportCategory&byImporter[id]=${this.importer.id}&perPage=20`;
      Repo.index(queryString)
        .then((response) => {
          const data = response.data.data;
          this.config = this.groupByCategory(data);
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({ preset: "error", text: errMsg });
        });
    },
  },
  computed: {
    temp_import() {
      return "temp_import";
    },
    import_file() {
      return "import_file";
    },
  },
};
